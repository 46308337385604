import BaseBean from "@/utils/BaseBean";

export interface IVideoPlayDataObj {
    utilInst:VideoPlayUtil
    refMap:Map<string,any>
    otherParams:any
}
export default class VideoPlayUtil extends BaseBean{
    public dataObj:IVideoPlayDataObj;

    constructor(proxy:any,dataObj:IVideoPlayDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
}